var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'options d-flex align-items-center  height-60px xlg-height-90px',
    {
      'justify-content-center': _vm.ifHeadMore,
    },
    {
      'justify-content-between': !_vm.ifHeadMore,
    } ],attrs:{"role":"options"}},[_c('Search',{directives:[{name:"show",rawName:"v-show",value:(_vm.ifHeadHome),expression:"ifHeadHome"}],class:{ 'order-0': _vm.ifHeadHome },on:{"focused":function($event){_vm.hide = false},"blured":function($event){_vm.hide = true}}}),(!_vm.ifHeadHome && !_vm.ifHeadMore)?[_c('GoBack',{attrs:{"showText":false}}),_c('p',{staticClass:"text-22 text-black weight-bold margin-start-2rem",attrs:{"role":"head"},domProps:{"textContent":_vm._s(_vm.getHead)}})]:_vm._e(),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.hide),expression:"hide"}],class:['height-60px xlg-height-90px', { 'order--1': _vm.ifHeadHome }],attrs:{"to":{ name: 'Home' }}},[_c('img',{staticClass:"resize-img d-block margin-x-1rem",attrs:{"src":"/logo/logo.png","alt":"logo"}})]),_c('Notifi',{directives:[{name:"show",rawName:"v-show",value:(_vm.ifHeadHome && _vm.hide),expression:"ifHeadHome && hide"}],staticClass:"xlg-margin-start-auto"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }