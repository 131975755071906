var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"\n    navbar\n    xlg-basis-90px\n    lg-width-100\n    flex-shrink-0\n    shadow\n    position-rel\n    lg-margin-top-auto\n    align-self-center\n    overflow-hidden\n  ",attrs:{"role":"navbar"}},[_c('ul',{staticClass:"text-align-center d-lg-flex lg-width-100 bg-light-1"},_vm._l((_vm.allItems),function(item){return _c('routerLink',{key:item.namePath,attrs:{"to":{ name: item.namePath },"exact":item.namePath == 'Home',"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('li',{class:[
          isExactActive && 'router-exact-active',
          isActive && 'router-active',
          'cursor-pointer lg-flex-grow-1 lg-flex-shrink-1 lg-padding-x-2rem lg-padding-y-1rem xlg-padding-y-2rem xlg-padding-x-1rem text-gray text-16 weight-bold',
          !item.name &&
            'd-lg-flex lg-justify-content-center lg-align-items-center' ],on:{"click":navigate}},[_c('GSvg',{staticClass:"svg-20 fill-gray",attrs:{"nameIcon":item.icon,"title":item.name}}),(item.name)?_c('span',{class:['d-block', { fix: item.name === 'سجل التبرعات' }]},[_vm._v(_vm._s(item.name))]):_vm._e()],1)]}}],null,true)})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }